import {Toast} from "vant";
import _ from 'lodash';

export default {
    message(fn = '') {
        return new Promise((s, e) => {
            if (_.isFunction(fn)) {
                fn()
                s()
            }
            setTimeout(() => {
                s()
            }, 2000)
        })
    },
    success(msq) {
        Toast.success(msq);
        return this.message()
    },
    error(msq) {
        Toast.fail(msq);
        return this.message()
    }
}
