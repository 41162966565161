import XEUtils from "xe-utils";
import _ from "lodash";
import moment from "moment/moment";


//隐藏中间字符串
export function hidden(val, frontLen, endLen, xing = '***') {
    val = XEUtils.toString(val)

    var len = val.length - frontLen - endLen;

    return val.substring(0, frontLen) + xing + val.substring(val.length - endLen);
}

export const cutString = (str, len) => {

    if (XEUtils.isUndefined(str)) {
        return ''
    }
    str = XEUtils.toString(str)
    //length属性读出来的汉字长度为1
    if (str.length * 2 <= len) {
        return str;
    }
    var strlen = 0;
    var s = "";
    for (var i = 0; i < str.length; i++) {
        s = s + str.charAt(i);
        if (str.charCodeAt(i) > 128) {
            strlen = strlen + 2;
            if (strlen >= len) {
                return s.substring(0, s.length - 1) + "...";
            }
        } else {
            strlen = strlen + 1;
            if (strlen >= len) {
                return s.substring(0, s.length - 2) + "...";
            }
        }
    }
    return s;
}

//金额转换
export function unitConvert(num) {
    if (num === null) {
        return '0元'
    }
    var moneyUnits = ["元", "万元", "亿元", "万亿"]
    var dividend = 10000;
    var curentNum = num;
    //转换数字
    var curentUnit = moneyUnits[0];
    //转换单位
    for (var i = 0; i < 4; i++) {
        curentUnit = moneyUnits[i]
        if (strNumSize(curentNum) < 5) {
            break;
        }
        curentNum = accDiv(curentNum, dividend);
    }
    var m = {num: 0, unit: ""};
    let numStr = curentNum.toString();
    let index = numStr.indexOf('.');
    let result = 0;
    if (index != -1) {
        result = numStr.slice(0, index + 7);
    } else {
        result = curentNum;
    }
    m.num = result;
    m.unit = curentUnit;
    return m.num + m.unit;
}

function unitConvertToTwo(num) {
    var moneyUnits = ["元", "万元", "亿元", "万亿"]
    var dividend = 10000;
    var curentNum = num;
    //转换数字
    var curentUnit = moneyUnits[0];
    //转换单位
    for (var i = 0; i < 4; i++) {
        curentUnit = moneyUnits[i]
        if (strNumSize(curentNum) < 5) {
            break;
        }
        curentNum = accDiv(curentNum, dividend);
    }
    debugger
    var m = {num: 0, unit: ""};
    let numStr = curentNum.toString();
    let index = numStr.indexOf('.');
    let result = 0;
    if (index != -1) {
        result = numStr.slice(0, index + 3);
    } else {
        result = curentNum;
    }

    m.num = result;
    m.unit = curentUnit;
    return m;
}

function strNumSize(tempNum) {
    var stringNum = XEUtils.toString(tempNum)
    var index = stringNum.indexOf(".")
    var newNum = stringNum;
    if (index != -1) {
        newNum = stringNum.substring(0, index)
    }
    return newNum.length
}

//除法函数，精度计算
function accDiv(arg1, arg2) {
    // console.log('accDiv',arg1,arg2)
    // var t1 = 0, t2 = 0, r1, r2;
    // try {
    //     t1 = XEUtils.toString(arg1).split(".")[1].length;
    // } catch (e) {
    //     console.log(e);
    // }
    // try {
    //     t2 = XEUtils.toString(arg2).split(".")[1].length;
    // } catch (e) {
    //     console.log(e);
    // }
    // r1 = Number(XEUtils.toString(arg1).replace(".", ""));
    // r2 = Number(XEUtils.toString(arg2).replace(".", ""));
    return _.divide(arg1, arg2);
}

//加法函数，用来得到精确的加法结果
//说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
//调用：accAdd(arg1,arg2)
//返回值：arg1加上arg2的精确结果
function accAdd(arg1, arg2) {
    // var r1, r2, m;
    // try {
    //     r1 = arg1.toString().split(".")[1].length;
    // } catch (e) {
    //     r1 = 0;
    // }
    // try {
    //     r2 = arg2.toString().split(".")[1].length;
    // } catch (e) {
    //     r2 = 0;
    // }
    // m = Math.pow(10, Math.max(r1, r2));
    return _.add(arg1, arg2);
}

//减法函数减数即为加数加上数的负数
function accSub(arg1, arg2) {
    var r1, r2, m;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
}

//乘法函数，用来得到精确的乘法结果
//说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
//调用：accMul(arg1,arg2)
//返回值：arg1乘以arg2的精确结果
function accMul(arg1, arg2) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    } catch (e) {
        console.log(e);
    }
    try {
        m += s2.split(".")[1].length;
    } catch (e) {
        console.log(e);
    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

export function setTitle(title) {
    if (_.isEmpty(title)) {
        title = '云左平台'
    }
    document.title = title
}

export function isAndroid() {
    //或者直接用正则，i是忽略大小写
    return /android/i.test(navigator.userAgent);

}

export function isIOS() {

    return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
}

