import http from "@/api/http"
import {Toast} from "vant"
import _ from 'lodash'
import to from 'await-to-js'
import {isIOS} from "@/api/GongJu";

function get_url() {
    let url = location.href
    if (isIOS()) {
        // alert(`isIOS:${_.get(window, 'shangYeMian', '1')}`)
        url = _.get(window, 'jin_ru_page', location.href)
    } else {
        // alert(`安卓:${location.href}`)
    }
    return _.split(url, '#')[0]
}

const wei_xin_basis = {
    //设置配置
    async setConfig(fn = undefined) {

        const [e, res] = await to(http.post("/q_w_x/kehuduan/get_config", {
            url: get_url()
        }))

        if (e) return Promise.reject(new Error('获取参数失败'))
        if (_.get(res, 'code') !== 1) return Promise.reject(new Error(_.get(res, 'code.msg')))
        window.wx.config({
            beta: true,
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名，见附录1
            jsApiList: [
                "updateAppMessageShareData",
                'hideMenuItems',
                'onMenuShareWeibo',
                'updateTimelineShareData',
                "onMenuShareAppMessage",
                "openEnterpriseChat",
                "openEnterpriseContact",
                "onMenuShareTimeline",
                'chooseImage',
                'previewImage',
                'uploadImage',
                'downloadImage',
                'previewFile',
                "openLocation",
                "getLocation",
                "scanQRCode",
                "selectEnterpriseContact",
                "selectExternalContact",
                'showMenuItems'
            ]
        });
        return new Promise((resolve, reject) => {
            window.wx.ready(() => {
                // alert('微信js配置成功')
                if (_.isFunction(fn)) fn()
                resolve()
            })
            window.wx.error(function (res) {
                return reject(new Error('res.errMsg'))
            });
        })

    },
    //企业微信登入 有效期五分钟
    login() {
        // window.wx.scanQRCode({
        //     desc: 'scanQRCode desc',
        //     needResult: 0, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
        //     scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
        //     success: function(res) {
        //         alert("扫码成功")
        //     },
        //     error: function(res) {
        //         if (res.errMsg.indexOf('function_not_exist') > 0) {
        //             alert('版本过低请升级')
        //         }
        //     }
        // });
    },
    //外部联系人选人接口
    get_wai_bu_lian_xi_ren(fn) {
        window.wx.invoke('selectExternalContact', {
            "filterType": 0, //0表示展示全部外部联系人列表，1表示仅展示未曾选择过的外部联系人。默认值为0；除了0与1，其他值非法。在企业微信2.4.22及以后版本支持该参数
        }, function (res) {
            if (res.err_msg == "selectExternalContact:ok") {
                //res.userIds 返回此次选择的外部联系人userId列表，数组类型
                fn(res.userIds)
            } else {
                //错误处理
                // Toast.fail("选择失败");
            }
        });
    },
    //打开会话
    open_hui_hua(neiBuId, waiBuId) {
        window.wx.openEnterpriseChat({
            // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
            userIds: neiBuId.join(';'),    //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
            externalUserIds: waiBuId.join(';'), // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
            groupName: '',  // 会话名称。单聊时该参数传入空字符串""即可。
            chatId: "", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
            success: function (res) {

                // var chatId = res.chatId; //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
                // 回调
            },
            fail: function (res) {
                if (res.errMsg.indexOf('function not exist') > -1) {
                    Toast.fail("版本过低请升级");
                } else {
                    Toast.fail("打开聊天失败");
                }
            }
        });
    },
    /**
     * 打开用户信息
     * @param type 是内部还是外部人员
     * @param userid 用户id
     */
    open_user_info(type, userid) {
        switch (type) {
            case "nei_bu":
                type = 1
                break;
            case "wai_bu":
                type = 2
        }
        try {
            window.wx.invoke('openUserProfile', {
                "type": type, //1表示该userid是企业成员，2表示该userid是外部联系人
                "userid": userid //可以是企业成员，也可以是外部联系人
            }, function (res) {
                if (res.err_msg != "openUserProfile:ok") {
                    Toast.fail("打开信息失败");
                }
            });
        } catch (e) {
            Toast.fail("请在企业微信内部打开");
        }
    },

    /**
     * 倒入联系人
     * 自动设置配置数据
     * 如果选择多个联系人，自动跳转到倒入页面
     * 如果单个联系人，就返回数据
     */
    guanLiangLianXiRen(fn) {
        // const self = this
        this.setConfig(() => {
            this.get_wai_bu_lian_xi_ren((userId) => {
                this.getUserInfo(userId.join(','), da => {
                    fn(da, userId)
                })
            })
        })
    },
    //更具外部用户id，获取用户信息，返回的数数组
    getUserInfo(userId, fn) {
        http.get("/q_w_x/KeHuDUan/get_ke_hu_info", {
            params: {userId: userId}
        }).then((da) => {
            if (da.code == 1) {
                fn(da.data)
            }
        })
    },
    //分享函数
    fenXiang({title, desc, link, imgUrl}) {
        this.setConfig(() => {
            weiXinFenXiang({
                title: title, // 分享标题
                desc: desc, // 分享描述
                link: link, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
                imgUrl: imgUrl, // 分享图标
            });
        })

    }
}


//
/**
 * 配置好微信参数后执行，微信分享 ,
 * 需在用户可能点击分享按钮前就先调用
 * @param title // 分享标题
 * @param desc   //分享描述
 * @param link  // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
 * @param imgUrl  // 分享图标
 */
export function weiXinFenXiang({title, desc, link, imgUrl}) {
    // alert('分享-1')
    showWeiXinFenXiang()
    window.wx.ready(() => {
        // alert('分享-2')
        window.wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: _.isEmpty(imgUrl) ? undefined : imgUrl, // 分享图标
            success: function () {
                // 设置成功
                // alert('ok-1')
            }
        })
// 自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
        window.wx.updateTimelineShareData({
            title: title, // 分享标题
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: _.isEmpty(imgUrl) ? undefined : imgUrl, // 分享图标
            success: function () {
                // 设置成功
                // alert('ok-2')
            }
        })
// 获取“分享到腾讯微博”按钮点击状态及自定义分享内容接口
        window.wx.onMenuShareWeibo({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: link, // 分享链接
            imgUrl: _.isEmpty(imgUrl) ? undefined : imgUrl, // 分享图标
            // success: function () {
            //     // 用户确认分享后执行的回调函数
            //     alert('ok-3')
            // },
            // cancel: function () {
            //     // 用户取消分享后执行的回调函数
            // }
        });
    })


}

//隐藏分享
export function hiddenWeiXinFenXiang() {
    window.wx.ready(()=>{
        window.wx.hideMenuItems({
            menuList: [
                "menuItem:share:appMessage",//发送给朋友:

                "menuItem:share:timeline",//分享到朋友圈:

                "menuItem:share:qq",//    分享到QQ:

                "menuItem:share:weiboApp",//分享到Weibo:

                "menuItem:favorite",//收藏:

                "menuItem:share:facebook",//分享到FB:

                "menuItem:share:QZone",//分享到 QQ 空间

            ] // 要显示的菜单项，所有 menu 项见附录3
        });
    })

}

//显示分享
export function showWeiXinFenXiang() {
    window.wx.ready(()=>{
        window.wx.showMenuItems({
            menuList: [
                "menuItem:share:appMessage",//发送给朋友:

                "menuItem:share:timeline",//分享到朋友圈:

                "menuItem:share:qq",//    分享到QQ:

                "menuItem:share:weiboApp",//分享到Weibo:

                "menuItem:favorite",//收藏:

                "menuItem:share:facebook",//分享到FB:

                "menuItem:share:QZone",//分享到 QQ 空间

            ] // 要显示的菜单项，所有 menu 项见附录3
        });
    })
}


export default wei_xin_basis
